import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import {CONSTANTS} from '../constants';

const useStyles = makeStyles({
  word: {
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 900,
    margin: '60px 10px 30px 10px',
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
  },
  show: {
    display: 'inline-block',
  },
  hide: {
    visibility: 'hidden',
  },
  slide: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  meansWrapper: {
    flexGrow: 1,
  },
  means: {
    display: 'flex',
    listStyle: 'none',
    alignItems: 'center',
    padding: 0,
    lineHeight: '50px',
    margin: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  mean: {
    marginLeft: '20px',
    marginRight: '20px',
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    fontSize: 16,
  },
});
let classes;

export default function SlideRenderer(props) {
  classes = useStyles();
  const {
    word,
    needsHintShow,
    needsWordShow,
    needsMeansShow,
  } = props;
  return (
    <div
      className={classes.slide}
    >
      <p
        className={classes.word}
      >
        <span className={clsx(needsHintShow ?
          classes.show :
          classes.hide)}>{word.word[0]}</span>
        <span className={clsx(needsWordShow ?
          classes.show :
          classes.hide)}>{word.word.slice(1,
          word.word.length)}</span>
      </p>
      <div className={classes.meansWrapper}>
        <ul className={clsx(
          needsMeansShow ? classes.show : classes.hide, classes.means)}>
          {word.means().filter(p => !!p).map((mean, i) => {
            return <li key={i} className={classes.mean}>{mean}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

