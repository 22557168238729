import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Props} from "../types/Voice";
import IconButton from "@material-ui/core/IconButton";
import {SpeechIcon} from "./icons/SpeechIcon";
import Typography from "@material-ui/core/Typography";
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '20px',
    },
    voices: {
      padding: 8,
      fontSize: '1.6rem',
      marginBottom: '20px',
    },
    rateWrapper: {
      display: 'flex',
      fontSize: 14,
    },
    rateRange: {
      flexGrow: 1,
      margin: '0 10px',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 50,
      marginBottom: 50,
    },
    buttonCaption: {
      marginTop: '-0.8rem',
      fontSize: '12px',
    },
    menuButton: {
      flexGrow: 1,
    },
    goToHome: {
      color: '#fff',
      background: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      padding: '20px 60px',
      borderRadius: '6px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);

export const Voice: FunctionComponent<Props> = (props: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const [voice, setVoice] = useState('');
  const [rate, setRate] = useState(1);

  const {history} = useReactRouter();

  useEffect(() => {
    setVoice(props.setting?.voice)
    setRate(props.setting?.rate || 1)
  }, [props.setting])

  const speech = () => {
    const synth = window.speechSynthesis;
    const utterThis = new SpeechSynthesisUtterance('english');
    const voices = speechSynthesis.getVoices()
    const selectedVoice: SpeechSynthesisVoice = voices.find(p => p.name === voice);
    utterThis.voice = selectedVoice || voices.find(p => p.lang.startsWith('en'))
    utterThis.lang = selectedVoice?.lang || utterThis.voice?.lang || 'en-US'
    utterThis.rate = rate || 1
    synth.speak(utterThis);
  }

  const onChangeVoice = (event) => {
    props.handleSubmit(event.target.value, rate)
    setVoice(event.target.value)
  }

  const onChangeRate = (event) => {
    props.handleSubmit(voice, event.target.value)
    setRate(event.target.value)
  }

  const onClickGoToHome = () => {
    history.replace(CONSTANTS.PATH.HOME);
  }

  return (
    <div
      className={classes.root}
    >
      <p
        className={classes.title}
      >発音パターン</p>
      <select
        defaultValue={voice}
        key={voice}
        className={classes.voices}
        onInput={onChangeVoice}
      >
        {props.voices.map(p => <option key={p.name}
                                       value={p.name}
        >{p.name}</option>)}
      </select>
      <p
        className={classes.title}
      >発音スピード</p>
      <div className={classes.rateWrapper}>
        <span>遅い</span>
        <input type={'range'}
               min={0.7}
               max={1.5}
               key={rate}
               step={0.1}
               defaultValue={rate}
               className={classes.rateRange}
               onChange={onChangeRate}
        />
        <span>速い</span>
      </div>

      <div className={classes.buttonWrapper}
           onClick={speech}>
        <IconButton
          color="inherit"
          aria-label="speech"
        >
          <SpeechIcon
            className={classes.menuButton}
          />
        </IconButton>
        <Typography variant="caption" className={classes.buttonCaption}>発音を聞く</Typography>
      </div>

      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  );
};
