import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Home} from '../components/Home';
import db from '../db';
import * as levelActions from '../modules/level/actions';
import * as settingActions from '../modules/setting/actions';
import * as wordActions from '../modules/word/actions';
import {Level} from "../entities/level";

const HomeContainer = ({store, actions}) => {
  const [totalGoaledCount, setTotalGoaledCount] = useState(0);
  const [totalStudyDays, setTotalStudyDays] = useState(0);

  useEffect(() => {
    actions.word.hideAnswer({});
    // chromeでは音声リストを取得するためには一度speechする必要があるので空文字を喋らせておく
    // https://techblog.istyle.co.jp/archives/3924
    actions.word.speech({text: ''});
  }, []);

  useEffect(() => {
    (async () => {
      const currentLevel: Level = await db.levels.where('experience')
      .belowOrEqual(store.histories.triedCount)
      .reverse()
      .first();
      const nextLevel: Level = await db.levels.where('experience')
      .above(store.histories.triedCount)
      .first();
      actions.level.setCurrentLevel(currentLevel);
      actions.level.setNextLevel(nextLevel);
    })();
    return () => {}
  }, []);

  // totalGoaledCount
  useEffect(() => {
    (async () => {
      setTotalGoaledCount(await calculateGoaledCount());
      setTotalStudyDays(await calculateStudyDays());
    })();
    return () => {}
  }, []);

  const calculateProgress = (): number => {
    const needExperience = store.levels.nextLevel.experience -
      store.levels.currentLevel.experience;
    const gotExperience = store.histories.triedCount -
      store.levels.currentLevel.experience;
    const progress: number = gotExperience / needExperience * 100;
    return progress || 0;
  };

  const calculateGoaledCount = async (): Promise<number> => {
    const totalGoaledCount: number = (await db.histories.toArray())
    .reduce((first, second) => first + second.goaledCount, 0);
    return totalGoaledCount;
  };

  const calculateStudyDays = async (): Promise<number> => {
    const totalStudyDays: number = (await db.histories
    .where('triedCount')
    .above(0)
    .count());
    return totalStudyDays;
  };

  return (
    <Home totalGoaledCount={totalGoaledCount}
          totalStudyDays={totalStudyDays}
          levelProgress={calculateProgress()}
          currentLevel={store.levels.currentLevel.level}
    />
  );
};

const mapStateToProps = state => {
  return {
    store: {
      histories: state.histories,
      levels: state.levels,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      setting: bindActionCreators(settingActions, dispatch),
      word: bindActionCreators(wordActions, dispatch),
      level: bindActionCreators(levelActions, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeContainer);

