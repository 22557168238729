import {types} from './types';

export const setCurrentLevel = currentLevel => ({
  type: types.SET_CURRENT_LEVEL,
  currentLevel: currentLevel,
});

export const setNextLevel = nextLevel => ({
  type: types.SET_NEXT_LEVEL,
  nextLevel: nextLevel,
});
