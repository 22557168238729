import {types} from './types';

const initialState = {
  text: 'init',
  questions: [],
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_ANSWER:
      return Object.assign({},
        state,
        {
          isShow: true,
          isHintShow: true,
        });
    case types.HIDE_ANSWER:
      return Object.assign({},
        state,
        {
          isShow: false,
          isHintShow: false,
        });
    case types.SHOW_HINT:
      return Object.assign({},
        state,
        {
          isShow: false,
          isHintShow: true,
        });
    case types.SET_WORD:
      return Object.assign({},
        state,
        {
          text: action.text,
        });
    case types.SET_QUESTIONS:
      return Object.assign({},
        state,
        {
          questions: action.questions,
        });
    default:
      return state;
  }
};
