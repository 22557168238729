import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import db from "../db";
import {Setting} from "../entities/setting";
import {Difficulty} from "../components/Difficulty";

const DifficultyContainer = ({store, actions}) => {

  const [setting, setSetting] = useState(null)

  useEffect(() => {
    (async () => {
      const setting: Setting = await db.settings.get(1);
      setSetting(setting)
    })();
    return () => {}
  }, []);

  const saveDifficulty = (difficulty: number) => {
    (async () => {
      await db.settings.update(1, {
        difficulty,
      });
    })();
  };

  return (
    <Difficulty
      setting={setting}
      handleSubmit={saveDifficulty}
    />
  );
};

const mapStateToProps = state => {
  return {
    store: {
      settings: state.settings,
    },
  };
};

export default connect(
  mapStateToProps,
)(DifficultyContainer);
