import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Study} from '../components/Study';
import {CONSTANTS} from '../constants';
import db from '../db';
import * as settingActions from '../modules/setting/actions';
import * as wordActions from '../modules/word/actions';
import {arrayUtil} from '../utils/array';
import {Word} from "../entities/word";
import useReactRouter from "use-react-router";
import {Setting} from "../entities/setting";

const StudyContainer = ({store, props, actions}) => {
  const [isQuestionsPrepared, setQuestionsPrepared] = useState(false);
  const {location} = useReactRouter();

  useEffect(() => {
    (async () => {
      if (location.pathname !== '/home') {
        return
      }
      const randomIndexes: number[] = await generateRandomIndexes(
        CONSTANTS.QUESTION.COUNT);
      const questions: Word[] = await db.words.where('wordId')
      .anyOf(randomIndexes)
      .toArray();
      actions.word.setWord(questions[0].word);
      actions.word.setQuestions(questions);
      setQuestionsPrepared(true);
    })();
    return () => {
    }
  }, [location.pathname]); // homeを開くたびに問題を更新する

  const generateRandomIndexes: (number) => Promise<number[]> = async (wordCount) => {
    let count: number;
    const setting: Setting = await db.settings.get(1);

    // 難易度設定に応じて単語数が変わる
    if (setting.difficulty === CONSTANTS.DIFFICULTY.EASY) {
      count = 1000;
    } else if (setting.difficulty === CONSTANTS.DIFFICULTY.JUNIOR) {
      count = 2000;
    } else {
      count = await db.words.count();
    }

    const wordIds: number[] = [...Array(count).keys()]
    return arrayUtil.shuffle(wordIds).slice(0, wordCount);
  };

  return (
    <Study
      isQuestionsPrepared={isQuestionsPrepared}
      children={props.children}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    store: {
      words: state.words,
      settings: state.settings,
    },
    props: ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setting: bindActionCreators(settingActions, dispatch),
      word: bindActionCreators(wordActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudyContainer);

