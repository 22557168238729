import {types} from './types';

export const setMode = mode => ({
  type: types.SET_MODE,
  mode: mode,
});
export const setLastLaunchDate = payload => ({
  type: types.SET_LAST_LAUNCHED_DATE,
  lastLaunchedDate: payload,
});
