import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as historyActions from '../modules/history/actions';
import * as levelActions from '../modules/level/actions';
import * as settingActions from '../modules/setting/actions';
import * as wordActions from '../modules/word/actions';
import {Word} from "../entities/word";
import {sliceIntoChunks} from "../utils/array";
import QRCodeSVG from "qrcode.react";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        height: 'calc(100vh - 64px)',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        '@media print': {
            display: 'block',
            position: 'absolute',
            zIndex: 1200,
            width: '210mm',
            overflow: 'visible',
        }
    },
    page: {
        display: 'block',
        position: 'relative',
        height: '270mm',
        padding: '16px',
        '@media print': {
            padding: 0,
            pageBreakAfter: 'always',
            pageBreakInside: 'avoid',
        }
    },
    footer: {
        display: 'flex',
        height: "auto",
        maxWidth: "250px",
        position: 'absolute',
        right: '16px',
        bottom: '0',
        flexDirection: 'column',
        alignItems: 'center',
        '@media print': {
            bottom: '5%',
        }
    },
    qrCode: {
        width: '120px !important',
        height: '120px !important',
        '@media print': {
            width: '250px !important',
            height: '250px !important',
        }
    },
    word: {
        fontSize: '14px',
        marginBottom: '10px',
        '@media print': {
            fontSize: '24px',
        }
    },
    list: {
        margin: 0,
        listStyleType: 'decimal',
        '& li': {
            marginBottom: '24px',
            '@media print': {
                marginBottom: '50px',
            }
        }
    }
});

const Print: FunctionComponent<any> = ({store, actions}) => {
    const classes = useStyles({});
    // const { location } = useReactRouter();

    const questions = (words: Word[]) => {
        const chunks = sliceIntoChunks(words, 5)
        return (
            <ul className={classes.list}>
                {chunks.at(0).map((word: Word, index: number) => <li
                    className={classes.word}
                    key={index}>{word.means().join(', ')}
                </li>)}
                {chunks.at(1).map((word: Word, index: number) => <li
                    className={classes.word}
                    key={index}>{word.word}
                </li>)}
            </ul>
        )
    }

    const qrCodeValue = (words: Word[]) => {
        const chunks = sliceIntoChunks(words, 5)
        return [...(chunks.at(0).map((word: Word) => word.word)), ...(chunks.at(1).map((word: Word) => word.means().join(', ')))]
            .reduce((accum, current, currentIndex) => accum + `${currentIndex + 1}. ${current}\n`, '')
            + `${window.location.host.startsWith('localhost') ? '' : '\nWebサイトで手軽に学習できます！(インストール不要, 無料) => https://chocolate-english.com\n\nLineスタンプ => https://store.line.me/stickershop/author/2049/ja'}`
    }

    return (
        <div className={classes.root}>
            {sliceIntoChunks(store.words.questions, 10).map((words: Word[], index: number) => <div
                className={classes.page}
                key={index}>
                {questions(words)}
                <div className={classes.footer}>
                    <QRCodeSVG
                        className={classes.qrCode}
                        includeMargin={true}
                        size={250}
                        value={qrCodeValue(words)} />
                    <p>
                        解答はこちら
                    </p>
                </div>
            </div>)}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        store: {
            words: state.words,
            histories: state.histories,
            levels: state.levels,
        },
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            word: bindActionCreators(wordActions, dispatch),
            setting: bindActionCreators(settingActions, dispatch),
            history: bindActionCreators(historyActions, dispatch),
            level: bindActionCreators(levelActions, dispatch),
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Print);
