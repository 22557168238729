import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {bindKeyboard, virtualize} from 'react-swipeable-views-utils';
import {Props} from '../types/ViewPager'
import {createStyles, makeStyles, useTheme} from "@material-ui/core/styles";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      width: 250,
    },
    content: {
      flexGrow: 1,
    },
  }),
);

export default function ViewPager(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    index,
    onChangeIndex,
    slideRenderer,
    slideCount,
  }: Props = props;
  return (
    <VirtualizeSwipeableViews
      className={classes.root}
      index={index}
      ignoreNativeScroll={true}
      enableMouseEvents={true}
      onChangeIndex={onChangeIndex}
      slideRenderer={slideRenderer}
      slideCount={slideCount}
      style={{height: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column'}}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
    />
  );
};
