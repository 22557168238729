import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React, {FunctionComponent} from 'react';
import {Circle} from './LevelProgress';
import {Props} from "../types/Home";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    records: {
      margin: 0,
      padding: '16px',
      listStyle: 'none',
      fontSize: '20px',
      fontWeight: 900,
    },
  }),
);

export const Home: FunctionComponent<Props> = (props: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <Circle
        size={'250'}
        centerText={`LV ${props.currentLevel || 0}`}
        styles={{}}
        progress={props.levelProgress || 0}/>
      <ul className={classes.records}>
        <li>ゴール回数: {props.totalGoaledCount}</li>
        <li>学習日数: {props.totalStudyDays}</li>
      </ul>
    </div>
  );
};
