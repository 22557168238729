import {combineReducers} from 'redux';
import {reducers as historyReducers} from './modules/history/reducers';
import {reducers as levelReducers} from './modules/level/reducers';
import {reducers as settingsReducers} from './modules/setting/reducers';
import {reducers as wordReducers} from './modules/word/reducers';

const rootReducer = combineReducers({
  settings: settingsReducers,
  words: wordReducers,
  histories: historyReducers,
  levels: levelReducers,
});

export default rootReducer;
