import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Props} from "../types/Difficulty";
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '20px',
    },
    difficulty: {
      padding: 8,
      fontSize: '1.6rem',
      marginBottom: '20px',
    },
    goToHome: {
      color: '#fff',
      background: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      padding: '20px 60px',
      borderRadius: '6px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);

export const Difficulty: FunctionComponent<Props> = (props: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const [difficulty, setDifficulty] = useState(1);

  const {history} = useReactRouter();

  useEffect(() => {
    setDifficulty(props.setting?.difficulty || CONSTANTS.DIFFICULTY.GENERAL)
  }, [props.setting])

  const onChangeDifficulty = (event) => {
    const value = parseInt(event.target.value)
    props.handleSubmit(value)
    setDifficulty(value)
  }

  const onClickGoToHome = () => {
    history.replace(CONSTANTS.PATH.HOME);
  }

  return (
    <div
      className={classes.root}
    >
      <p
        className={classes.title}
      >難易度</p>
      <select
        defaultValue={difficulty}
        key={difficulty}
        className={classes.difficulty}
        onInput={onChangeDifficulty}
      >
        <option value={0}>一般</option>
        <option value={1}>小〜中学校(2000語)</option>
        <option value={2}>やさしい(1000語)</option>
      </select>


      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  );
};
