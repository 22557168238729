import {types} from './types';

interface HistoryStore {
  triedCount: number
}

const initialState: HistoryStore = {
  triedCount: 0,
};

export const reducers = (state: HistoryStore = initialState, action) => {
  switch (action.type) {
    case types.SET_TRIED_COUNT:
      return Object.assign({},
        state,
        {
          triedCount: action.triedCount,
        });
    case types.INCREMENT_TRIED_COUNT:
      return Object.assign({},
        state,
        {
          triedCount: state.triedCount + 1,
        });
    default:
      return state;
  }
};
