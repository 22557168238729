import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React, {FunctionComponent} from 'react';
import {Props} from "../types/Voice";
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
    },
    licenseText: {
      fontSize: '12px',
      paddingLeft: 0,
      paddingBottom: 60,
      listStyle: 'none',
      textAlign: 'center',
    },
    goToHome: {
      color: '#fff',
      background: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      padding: '20px 60px',
      borderRadius: '6px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);

export const License: FunctionComponent<Props> = (props: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const {history} = useReactRouter();

  const onClickGoToHome = () => {
    history.goBack();
  }

  return (
    <div
      className={classes.root}
    >
      <ul
        className={classes.licenseText}
      >
        <li>
          <a href="https://www.heropatterns.com/" target={'_blank'} rel={'noopener noreferrer'}>CC by 4.0 https://www.heropatterns.com/</a>
        </li>
        <li>
          <a href="http://www.onlinewebfonts.com" target={'_blank'} rel={'noopener noreferrer'}>oNline Web Fonts</a>
        </li>
      </ul>

      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  );
};
