import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {EnglishIcon} from "./icons/EnglishIcon";
import {JapaneseIcon} from "./icons/JapaneseIcon";
import {SpeechIcon} from "./icons/SpeechIcon";
import {LicenseIcon} from "./icons/LicenseIcon";
import {DifficultyIcon} from "./icons/DifficultyIcon";
import {Print} from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 280,
    },
    navLink: {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none',
    },
    menuText: {
      fontSize: '0.8rem',
      textDecoration: 'none',
    },
    subMenu: {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none',
    },
    pngIcon: {
      width: '1.6rem',
      height: '1.6rem',
    },
  }),
);

export const SideList = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={props.toggleDrawer('left', false)}
      onKeyDown={props.toggleDrawer('left', false)}
    >
      <List>
        <NavLink to={'/en'} className={classes.navLink} exact>
          <NavLink to={'/print'} className={classes.navLink} exact>
            <ListItem button key={5}>
              <ListItemIcon>
                <Print/>
              </ListItemIcon>
              <ListItemText
                  classes={{primary: classes.menuText}}
                  primary={'問題を印刷する'}/>
            </ListItem>
          </NavLink>
          <ListItem button key={1}>
            <ListItemIcon>
              <EnglishIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.menuText}}
              primary={'英語 -> 日本語で学習する'}/>
          </ListItem>
        </NavLink>
        <NavLink to={'/ja'} className={classes.navLink} exact>
          <ListItem button key={2}>
            <ListItemIcon>
              <JapaneseIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.menuText}}
              primary={'日本語 -> 英語で学習する'}/>
          </ListItem>
        </NavLink>
        <NavLink to={'/difficulty'} className={classes.navLink} exact>
          <ListItem button key={3}>
            <ListItemIcon>
              <DifficultyIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.menuText}}
              primary={'難易度設定'}/>
          </ListItem>
        </NavLink>
        <NavLink to={'/voices'} className={classes.navLink} exact>
          <ListItem button key={4}>
            <ListItemIcon>
              <SpeechIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.menuText}}
              primary={'発音パターン設定'}/>
          </ListItem>
        </NavLink>
      </List>
      <Divider/>
      <List>
        <NavLink to={'/license'} className={classes.navLink} exact>
          <ListItem button key={3}>
            <ListItemIcon>
              <LicenseIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.menuText}}
              primary={'ライセンス'}/>
          </ListItem>
        </NavLink>
      </List>
    </div>
  );
};
