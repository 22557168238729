import {types} from './types';

const initialState = {
  mode: 'ja',
  lastLaunchedDate: null,
  voice: null,
  rate: null,
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MODE:
      return Object.assign({},
        state,
        {
          mode: action.mode,
        });
    case types.SET_LAST_LAUNCHED_DATE:
      return Object.assign({},
        state,
        {
          lastLaunchedDate: action.lastLaunchedDate,
        });
    default:
      return state;
  }
};
