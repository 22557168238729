import {types} from './types';

export const showAnswer = () => ({
  type: types.SHOW_ANSWER,
});

export const hideAnswer = () => ({
  type: types.HIDE_ANSWER,
});

export const showHint = () => ({
  type: types.SHOW_HINT,
});

export const setWord = payload => {
  return {
    type: types.SET_WORD,
    text: payload,
  };
};

export const setQuestions = payload => {
  return {
    type: types.SET_QUESTIONS,
    questions: payload,
  };
};

export const speech = payload => {
  const synth = window.speechSynthesis;
  const utterThis: SpeechSynthesisUtterance = new SpeechSynthesisUtterance(payload.text);
  const voices = speechSynthesis.getVoices()
  const selectedVoice: SpeechSynthesisVoice = voices.find(p => p.name === payload.voice);
  utterThis.voice = selectedVoice || voices.find(p => p.lang.startsWith('en'))
  utterThis.lang = selectedVoice?.lang || utterThis.voice?.lang || 'en-US'
  utterThis.rate = payload.rate || 1

  synth.speak(utterThis);

  return {
    type: types.SPEECH,
  };
};
