import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import useReactRouter from 'use-react-router';
import {ToolBar} from '../components/ToolBar';
import * as wordActions from '../modules/word/actions';
import {ContainerProps} from "../types/ToolBar";
import {Setting} from "../entities/setting";
import db from "../db";
import {CONSTANTS} from "../constants";

const ToolBarContainer = ({store, props, actions}) => {
  const {history, location} = useReactRouter();
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    (async () => {
      const settings: Setting = await db.settings.get(1);
      setSettings(settings)
    })();
    return () => {}
  }, [location.pathname]);

  const startStudy = () => {
    history.push(`/${settings.mode || CONSTANTS.SETTING.MODE.JA}`);
  };

  const showAnswer = async () => {
    if (store.settings.mode === 'ja') {
      if (store.words.isHintShow) {
        actions.word.showAnswer();
      } else {
        actions.word.showHint();
      }
    } else {
      actions.word.showAnswer();
    }
  };

  const speechText = () => {
    actions.word.speech({
      text: store.words.text,
      voice: settings.voice,
      rate: settings.rate,
    });
  };

  return (
    <ToolBar
      position="fixed"
      startStudy={startStudy}
      showAnswer={showAnswer}
      speechText={speechText}
      isHome={location.pathname === '/home'}
      isPrint={location.pathname === '/print'}
      toggleDrawer={props.toggleDrawer}
    />
  );
};

const mapStateToProps = (state, ownProps: ContainerProps) => {
  return {
    store: {
      words: state.words,
      settings: state.settings,
    },
    props: ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      word: bindActionCreators(wordActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolBarContainer);

