import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import React from 'react';

export default function ProgressBar(props) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      progressBar: {
        marginLeft: 0,
        width: `${props.progress}%`,
        transition: 'all 200ms 0s ease',
      },
    }),
  );

  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <hr style={props.styles} className={classes.progressBar}/>
  );
};
