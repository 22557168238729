import dayjs from 'dayjs';
import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Init from './components/Init';
import {CONSTANTS} from './constants';
import HomeContainer from './containers/HomeContainer';
import StudyContainer from './containers/StudyContainer';
import ViewPagerContainer from './containers/ViewPagerContainer';
import * as settingActions from './modules/setting/actions';
import LandingContainer from "./containers/LandingContainer";
import VoiceContainer from "./containers/VoiceContainer";
import {License} from "./components/License";
import DifficultyContainer from "./containers/DifficultyContainer";
import Print from "./components/Print";

const App = ({store}) => {

  const isReady = (): boolean => {
    const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
    // TODO store.settings.isTodayLaunched() と訊きたい
    return store.settings.lastLaunchedDate === today;
  };

  if (!isReady()) {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LandingContainer}/>
          <Route exact path="*" component={Init}/>
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingContainer}/>
        <Route exact path="/voices" component={VoiceContainer}/>
        <Route exact path="/difficulty" component={DifficultyContainer}/>
        <Route exact path="/license" component={License}/>
        <StudyContainer>
          <Route exact path="/home" component={HomeContainer}/>
          <Route exact path="/:language(en|ja)" component={ViewPagerContainer}/>
          <Route exact path="/print" component={Print}/>
        </StudyContainer>
      </Switch>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    store: {
      settings: state.settings,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setting: bindActionCreators(settingActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
