import {types} from './types';

const initialState = {
  currentLevel: {},
  nextLevel: {},
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_LEVEL:
      return Object.assign({},
        state,
        {
          currentLevel: action.currentLevel,
        });
    case types.SET_NEXT_LEVEL:
      return Object.assign({},
        state,
        {
          nextLevel: action.nextLevel,
        });
    default:
      return state;
  }
};
