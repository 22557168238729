import makeStyles from '@material-ui/core/styles/makeStyles';
import confetti from 'canvas-confetti';
import dayjs from 'dayjs';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import useReactRouter from 'use-react-router';
import GoalSlideRenderer from '../components/GoalSlideRenderer';
import {Circle} from '../components/LevelProgress';
import ProgressBar from '../components/ProgressBar';
import SlideRenderer from '../components/SlideRenderer';
import ViewPager from '../components/ViewPager';
import {CONSTANTS} from '../constants';
import db from '../db';
import * as historyActions from '../modules/history/actions';
import * as levelActions from '../modules/level/actions';
import * as settingActions from '../modules/setting/actions';
import * as wordActions from '../modules/word/actions';
import {OnChangeIndex} from '../types/ViewPager'
import {Level} from "../entities/level";
import {Word} from "../entities/word";

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  attention: {
    'position': 'absolute',
    'bottom': 0,
    'fontSize': '12px',
    'right': 0,
    'marginRight': '16px',
  }
});

const ViewPagerContainer: FunctionComponent<any> = ({store, actions}) => {
  const classes = useStyles({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isGoaled, setGoaled] = useState(false);
  const {match, history} = useReactRouter();
  const questions = store.words.questions.slice(0, 10) ?? [];

  useEffect(() => {
    (async () => {
      await db.settings.update(1, {
        mode: match.params.language,
      });
      actions.setting.setMode(match.params.language);
    })();
    return () => {}
  }, [match.params.language]); // homeを開くたびに問題を更新する

  const onChangeIndex: OnChangeIndex = async (index, indexLatest) => {
    if (!isGoaled && index === questions.length) {
      await goaled();
      shotConfetti();
    }
    actions.setting.setMode(match.params.language);
    actions.word.hideAnswer({});
    setCurrentIndex(index);
    const currentProgress = index / questions.length * 100;
    if (progress < currentProgress) {
      // TODO: todayをdryにしたい
      const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
      await db.histories.where({date: today})
      .modify(row => {
        row.triedCount++;
      });
      actions.history.incrementTriedCount();
      setProgress(currentProgress);
    }
    const currentWord = questions[index];
    if (currentWord) {
      actions.word.setWord(currentWord.word);
    }
  };

  const goaled = async (): Promise<void> => {
    setGoaled(true);
    const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
    return await db.histories.where({date: today})
    .modify(row => {
      row.goaledCount++;
    });
  };

  const needsHintShow = (index: number): boolean => {
    if (match.params.language === CONSTANTS.SETTING.MODE.EN) {
      return true;
    }
    return (store.words.isShow || store.words.isHintShow) && currentIndex ===
      index;
  };

  const needsWordShow = (index: number): boolean => {
    if (match.params.language === CONSTANTS.SETTING.MODE.EN) {
      return true;
    }
    return store.words.isShow && currentIndex === index;
  };

  const needsMeansShow = (index: number): boolean => {
    if (match.params.language === CONSTANTS.SETTING.MODE.JA) {
      return true;
    }
    return store.words.isShow && currentIndex === index;
  };

  const goToHome = () => {
    history.replace('/home');
  };

  const calculateProgress = (): number => {
    const needExperience = store.levels.nextLevel.experience -
      store.levels.currentLevel.experience;
    const gotExperience = store.histories.triedCount -
      store.levels.currentLevel.experience;
    const progress = gotExperience / needExperience * 100;
    if (progress === 100) {
      shotConfetti();
    }
    return progress || 0;
  };

  const onCircleTransitionEnd = () => {
    (async () => {
      const currentLevel: Level = await db.levels.where('experience')
      .belowOrEqual(store.histories.triedCount)
      .reverse()
      .first();
      const nextLevel: Level = await db.levels.where('experience')
      .above(store.histories.triedCount)
      .first();
      actions.level.setCurrentLevel(currentLevel);
      actions.level.setNextLevel(nextLevel);
    })();
  };

  const shotConfetti = () => {
    const random = (min: number, max: number): number => {
      return Math.random() * (max - min) + min;
    };

    const shotLimit: number = 3;
    let shotTimes: number = 0;
    const interval = setInterval(() => {
        confetti({
          angle: random(55, 125),
          spread: random(50, 70),
          particleCount: random(50, 100),
          gravity: 2,
          origin: {
            y: 1.3,
          },
        });
        shotTimes++;
        if (shotTimes >= shotLimit) {
          clearInterval(interval);
        }
      },
      300,
    );
  };

  const slideRenderer: FunctionComponent<any> = (params) => {
    const {index, key} = params;

    const word: Word = questions[index];
    if (!word) {
      return <GoalSlideRenderer
        key={key}
        onPrimaryButtonClick={goToHome}
      />;
    }
    return <SlideRenderer
      key={key}
      word={word}
      needsHintShow={needsHintShow(index)}
      needsWordShow={needsWordShow(index)}
      needsMeansShow={needsMeansShow(index)}
    />;
  };

  return (
    <div
      className={classes.root}
    >
      {store.levels.currentLevel.level === 1 &&
        <p className={classes.attention}>スワイプして次の単語へ → </p>
      }
      <ProgressBar
        styles={{
          position: 'absolute',
          zIndex: 9,
          bottom: 0,
          margin: 0,
          border: '4px solid #ff3b3b9e',
        }}
        progress={progress}/>
      <Circle
        size={'50'}
        centerText={`LV ${store.levels.currentLevel.level || 0}`}
        styles={{
          position: 'absolute',
          top: 10,
          left: 10,
        }}
        onAnimationEnd={onCircleTransitionEnd}
        progress={calculateProgress()}/>
      <ViewPager
        index={currentIndex}
        enableMouseEvents={true}
        onChangeIndex={onChangeIndex}
        slideRenderer={slideRenderer}
        slideCount={CONSTANTS.QUESTION.COUNT + 1}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    store: {
      words: state.words,
      histories: state.histories,
      levels: state.levels,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      word: bindActionCreators(wordActions, dispatch),
      setting: bindActionCreators(settingActions, dispatch),
      history: bindActionCreators(historyActions, dispatch),
      level: bindActionCreators(levelActions, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewPagerContainer);
