import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import {Props} from "../types/ToolBar";
import {SpeechIcon} from "./icons/SpeechIcon";
import {AnswerIcon} from "./icons/AnswerIcon";
import {StartIcon} from "./icons/StartIcon";
import {CONSTANTS} from "../constants";
import {Print} from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            position: 'inherit',
            background: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
            '@media print': {
                display: 'none',
            }
        },
        homeButtons: {
            flexGrow: 3,
            display: 'flex',
            justifyContent: 'center',
        },
        controlButtons: {
            flexGrow: 3,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '8px'
        },
        speechButton: {
            marginLeft: '16px'
        },
        buttonCaption: {
            marginTop: '-0.8rem',
            fontSize: '12px',
        },
        menuButton: {
            flexGrow: 1,
        },
    }),
);

export const ToolBar = (props: Props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const homeButtons = (
        <div className={classes.homeButtons}>
            <div className={classes.buttonWrapper}>
                <IconButton
                    color="inherit"
                    focusRipple={true}
                    autoFocus={true}
                    onClick={props.startStudy}
                >
                    <StartIcon className={classes.menuButton}/>
                </IconButton>
                <Typography variant="caption" className={classes.buttonCaption}>はじめる</Typography>
            </div>
        </div>
    );

    const printButtons = (
        <div className={classes.homeButtons}>
            <div className={classes.buttonWrapper}>
                <IconButton
                    color="inherit"
                    focusRipple={true}
                    autoFocus={true}
                    onClick={window.print}
                >
                    <Print className={classes.menuButton}/>
                </IconButton>
                <Typography variant="caption" className={classes.buttonCaption}>印刷する</Typography>
            </div>
        </div>
    );

    return (
        <AppBar
            className={classes.appBar}
        >
            <Toolbar>
                <div className={classes.buttonWrapper}>
                    <div
                        onClick={() => {
                            props.toggleDrawer('left', true)();
                        }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                        >
                            <MenuIcon
                                className={classes.menuButton}
                            />
                        </IconButton>
                    </div>
                    <Typography variant="caption"
                                className={classes.buttonCaption}>メニュー</Typography>
                </div>
                {props.isHome ? homeButtons : props.isPrint ? printButtons
                    :
                    <div className={classes.controlButtons}>
                        <div className={classes.buttonWrapper}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={props.showAnswer}
                            >
                                <AnswerIcon
                                    className={classes.menuButton}
                                />
                            </IconButton>
                            <Typography variant="caption"
                                        className={classes.buttonCaption}>答えを見る</Typography>
                        </div>
                        <div className={clsx(classes.buttonWrapper, classes.speechButton)}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={props.speechText}
                            >
                                <SpeechIcon
                                    className={classes.menuButton}
                                />
                            </IconButton>
                            <Typography variant="caption"
                                        className={classes.buttonCaption}>発音を聞く</Typography>
                        </div>
                    </div>
                }
            </Toolbar>
        </AppBar>
    );
};
