import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Voice} from "../components/Voice";
import db from "../db";
import {Setting} from "../entities/setting";

const VoiceContainer = ({store, actions}) => {

  const [voices, setVoices] = useState([]);
  const [setting, setSetting] = useState(null)

  useEffect(() => {
    (async () => {
      const setting: Setting = await db.settings.get(1);
      setSetting(setting)
    })();
    return () => {}
  }, []);

  useEffect(() => {
    const voices = window.speechSynthesis
    .getVoices()
    .filter(p => p.lang.startsWith('en'))
    setVoices(voices);
  }, []);

  const saveVoice = (voice: string, rate: number) => {
    (async () => {
      await db.settings.update(1, {
        voice,
        rate,
      });
    })();
  };

  return (
    <Voice
      voices={voices}
      handleSubmit={saveVoice}
      setting={setting}
    />
  );
};

const mapStateToProps = state => {
  return {
    store: {
      settings: state.settings,
    },
  };
};

export default connect(
  mapStateToProps,
)(VoiceContainer);

