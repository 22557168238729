import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useEffect, useRef, useState} from 'react';
import {CONSTANTS} from '../constants';
import {HomeIcon} from './icons/HomeIcon';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  adView: {
    margin: '30px 0 20px 0',
  },
  slide: {
    padding: 15,
    color: '#fff',
  },
  goToHome: {
    color: '#fff',
    background: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    padding: '20px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  goToHomeText: {
    marginLeft: '8px',
  },
  goalText: {
    fontSize: '40px',
    fontWeight: 900,
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    marginTop: '60px',
    marginBottom: 0,
  },
  chosenGoalText: {
    fontWeight: 900,
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    marginTop: 0,
  },
});
let classes;

export default function GoalSlideRenderer(props) {
  classes = useStyles();
  const adViewRef = useRef(null);

  const {
    onPrimaryButtonClick,
  } = props;

  const [goalText, setGoalText] = useState('');

  useEffect(() => {
    setGoalText(chooseGoalTexts());
  }, []);

  useEffect(() => {
    const html = document.getElementById('chocolateAdView').innerHTML
    document.getElementById('goaledChocolateAdView').innerHTML = html
  }, []);

  const chooseGoalTexts = (): string => {
    const texts: string[] = [
      'おつかれさま！',
      'がんばったね！',
      'えらい！',
      'やったね！',
      'その調子！',
      'がんばってるね！',
      'またきてね！',
      'いい感じ！',
      'Chocolateをみんなにも教えてね！',
      'もう一回やる？',
      '意外な意味の単語もあるよね！',
      'いいね！',
      'どんどんレベルアップしよう！',
      'お勉強してすごい！',
      'やる気だね！',
      'なまけずに勉強してえらい！',
      'コツコツ勉強してえらい！',
    ];
    return texts[(new Date()).getTime() % 17];
  };

  return (
    <div className={classes.root}>
      <p className={classes.goalText}>GOAL!</p>
      <p className={classes.chosenGoalText}>{goalText}</p>
      <div id={'goaledChocolateAdView'} className={classes.adView} ref={adViewRef}/>
      <div
        className={classes.slide}
        onClick={onPrimaryButtonClick}
      >
        <p
          className={classes.goToHome}
        >
          <HomeIcon/>
          <span className={classes.goToHomeText}>ホームへ戻る</span>
        </p>
      </div>
    </div>
  );
};

