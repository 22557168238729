import React from 'react';
import {connect} from 'react-redux';
import {Landing} from "../components/Landing";

const LandingContainer = () => {
  return (
    <Landing/>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingContainer);


