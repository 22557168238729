import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

type Props = React.HTMLProps<SvgIconProps>;

export const JapaneseIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414"><path d="M15.056 23.27c2.736.196 5.779.293 9.131.293 4.005 0 7.83-.13 11.476-.39.13-1.107.309-2.735.537-4.883.52-4.362.846-7.471.977-9.326l7.373.488a413.622 413.622 0 00-1.465 13.135c9.505-1.01 18.994-2.816 28.467-5.42l.83 6.885c-9.213 2.31-19.222 4.02-30.03 5.127-.39 3.939-.651 8.235-.781 12.89 4.427-1.595 9.668-2.67 15.723-3.222.716-1.953 1.204-4.086 1.465-6.397l7.422 1.709c-.521 2.279-.962 3.89-1.32 4.834 6.707.912 11.98 2.93 15.82 6.055 5.6 4.622 8.4 10.66 8.4 18.115 0 8.203-3.37 14.876-10.109 20.02-5.174 4.004-12.336 6.673-21.483 8.007l-4.297-6.64c7.943-.88 14.274-2.865 18.994-5.957 6.054-3.906 9.081-9.082 9.081-15.528 0-6.543-2.766-11.572-8.3-15.087-2.701-1.791-6.07-2.93-10.108-3.418-4.947 12.662-11.604 22.933-19.97 30.81.196 2.246.717 4.98 1.563 8.203l-7.178 2.686c-.195-.912-.57-3.11-1.123-6.592-5.828 4.134-11.117 6.2-15.87 6.2-6.607 0-9.911-3.466-9.911-10.4 0-4.687 1.48-9.358 4.443-14.013 2.767-4.264 6.56-8.09 11.377-11.474 1.628-1.205 4.313-2.8 8.057-4.786.13-4.46.439-9.603.928-15.43-5.013.39-9.782.587-14.307.587-2.41 0-4.134-.033-5.176-.098l-.636-6.982zM34.15 52.08c-2.312 1.27-4.835 3.239-7.569 5.908-2.832 2.735-5.078 5.648-6.738 8.74-1.335 2.54-2.05 4.786-2.149 6.739-.163 3.613 1.14 5.42 3.906 5.42 4.2 0 8.692-2.198 13.477-6.592-.52-5.208-.83-11.947-.928-20.215zm21.337-7.52c-5.37.749-10.14 1.986-14.305 3.712 0 8.333.13 14.762.39 19.287 5.925-6.576 10.562-14.242 13.916-22.998z" fillRule="nonzero"/></svg>
    </SvgIcon>
  );
};
