export const CONSTANTS = {
  PATH: {
    ROOT: '/',
    HOME: '/home',
    SETTINGS: '/settings'
  },
  SETTING: {
    MODE: {
      EN: 'en',
      JA: 'ja',
    },
  },
  QUESTION: {
    COUNT: 100,
  },
  DATE: {
    FORMAT: {
      YMD: 'YYYYMMDD',
    },
  },
  FONT: {
    PRIMARY_TEXT_COLOR: '#5a2600'
  },
  MAX_WORD_COUNT: 8200,
  DIFFICULTY: {
    GENERAL: 0,
    JUNIOR: 1,
    EASY: 2
  }
};
