import {types} from './types';

export const setTriedCount = triedCount => ({
  type: types.SET_TRIED_COUNT,
  triedCount: triedCount,
});

export const incrementTriedCount = () => ({
  type: types.INCREMENT_TRIED_COUNT,
});
