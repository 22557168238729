import Dexie from 'dexie';
import {ChocolateDb} from './types/ChocolateDb';
import {History} from './entities/history';
import {Level} from './entities/level';
import {Setting} from './entities/setting';
import {Word} from './entities/word';

const db: ChocolateDb = new Dexie('Chocolate');

db.version(1).stores({
  words: '++id, &wordId, word',
  settings: '++id',
  histories: '++id, &date, triedCount',
  levels: '++id, experience',
});

db.words.mapToClass(Word);
db.settings.mapToClass(Setting);
db.histories.mapToClass(History);
db.levels.mapToClass(Level);

// Init DB with some default statuses:
db.on('populate', () => {
  db.settings.add({id: 1, hint: true});
});

export default db;
